export default {
    portfolioBattle: 'Битва<br>Портфелей',
    startPlaceholder: 'Введи почту, чтобы начать баттл',
    hi: 'Привет',
    portfolioNameInfo: 'Придумай название для своего портфеля (его увидят все) и начни инвестировать!',
    portfolioName: 'Название портфеля',
    continue: 'Продолжить',
    yourBalance: 'Твой баланс',
    invested: 'Инвестировано',
    editInfo: 'Вы можете редактировать портфель до начала битвы',
    selectAssets: 'Выбери монеты',
    add: 'Добавить',
    edit: 'Редактировать',
    done: 'Готово',
    emailError: 'Проверьте правильность почты',
    tooLongName: 'Слишком длинное название',
    publish: 'Опубликовать',
    portfolioExist: 'Такое название портфеля уже существует измените название и попробуйте ещё раз',
    portfolioCreated: 'Твой портфель успешно опубликован! Пока батл не начался, ты можешь редактировать свой портфель.',
    emailInfo: 'Письмо с подтверждением выслали на почту (если не нашел - проверь спам). Мы подождем...',
    retryAfter: 'Попробуйте ещё раз через {count} секунд',
    coinSearch: 'Поиск монет',
    update: 'Обновить',
    moniDescription: 'Самый простой способ для отслеживания твоих крипто портфелей и анализа рынка.',
    moniTalksDescription: 'Получай 3-х минутный дайджест с самыми горячими событиями из мира крипты прямо в свой почтовый ящик. Каждый день.',
    getin: 'Войти',
    getinText: 'Письмо с подтверждением выслали на почту. Мы подождем...',
    spaminfo: 'если не нашел - проверь спам',
    signoutInfo: 'Уже уходишь',
    signout: 'Выйти',
    binanceDescription: 'Криптовалютная биржа №1 в мире, как часть экосистемы по распространению свободы денег.',
    incryptedDescription: 'Медиа сеть о криптовалютах и блокчейн на русском. Говорим простым языком о крипте с 2017 года.',
    begin: 'Начало',
    end: 'Конец',
    prize: 'Вознаграждение',
    iUnderstood: 'Красавчик!<br>Псс, ты только никому не говори, но мы за тебя болеем.',
    wannaBePartOfNext: 'Хочешь учавствовать в следующем?',
    nextInfo: 'Нажми Войти и когда начнется след. баттл мы оповестим тебя. Там же ты сможешь прочитать про условия участия, бла-бла',
    archive: 'АРХИВ',
    archiveCaption: 'это прошедшие батлы',
    winner: 'Победитель',
    dates: 'Даты',
    all: 'ВСЕ',
    tookPart: 'ПРИНИМАЛ УЧАСТИЕ',
    we: 'МЫ',
    who: 'кто',
    more: 'Подробнее',
    withLove: 'Сделано с любовью',
    inProcess: 'Определяется',
    liveCaption: 'это значит прямо сейчас',
    hiChampion: 'Привет, чемпион',
    email: 'Почта',
    upcomingBattles: 'ПРЕДСТОЯЩИЕ БАТЛЫ',
    upcomingBattlesCaption: 'это значит, что скоро',
    registration: 'Регистрация',
    start: 'Старт',
    final: 'Финал',
    members: 'Участников',
    takePart: 'Участвую',
    join: 'Участвовать',
    registrationWillBegin: 'РЕГИСТРАЦИЯ НАЧНЁТСЯ',
    battleWillBegin: 'БАТЛ НАЧНЁТСЯ',
    registerWillEnd: 'РЕГИСТРАЦИЯ ЗАВЕРШИТСЯ',
    in: 'ЧЕРЕЗ',
    showMore: 'Показать ещё',
    checkRules: 'Проверь еще раз',
    terms: 'Условия участия',
    termsMoreInfo: 'а то в случае победы (а мы очень верим в тебя) ты не сможешь получить призовые',
    info404: 'ВЕРНУТЬСЯ НА ГЛАВНУЮ',
    alldone: 'ВСЕ СДЕЛАЛ',
    maxSumPopup: 'Можно выбрать один актив не более чем на $400',
    fillPortfolioName: 'Заполни название портфеля',
    battleWillEnded: 'БИТВА ЗАВЕРШИТСЯ ЧЕРЕЗ',
    battleEnded: 'БИТВА ЗАВЕРШЕНА',
    battleName: 'Название<br>батла',
    becomePartner: 'Стать партнером Moni Battle',
    becomePartnerShort: 'Стать партнером',
    becomePartnerLogoInfo: 'Здесь может быть твое лого',
    benefits: {
        first: {
            title: 'Комплексное продвижение',
            text: 'О твоем проекте узнают подписчики всех площадок Moni и наших партнеров.'
        },
        second: {
            title: 'Контакты',
            text: 'На батле можно завести полезные знакомства с интересными и именитыми проектами.',
        },
        third: {
            title: 'Имидж',
            text: 'Прекрасный инфоповод!',
        },
        fourth: {
            title: 'Контент',
            text: 'Аудитория твоего проекта будет крайне рада такому развлечению.',
        },
    },
    infoForPartners: 'Чтобы стать партнером, нужно отправить письмо с описанием твоего проекта',
    digestInfo: 'Самые горячие крипто новости 🌶 в&nbsp;твоей почте в виде 5-минутной выжимки. <span class="font-weight-semiBold">Каждый&nbsp;день</span>.',
    subscribe: 'Подписаться',
    rules: 'Этот баттл платный',
};
